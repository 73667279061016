var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      ref: "select_element",
      staticClass: "companySelectForSearch",
      attrs: {
        value: _vm.valueTitle,
        clearable: _vm.clearable,
        filterable: _vm.searchable,
        "filter-method": _vm.filterCompany,
        placeholder: "请选择"
      },
      on: { clear: _vm.resetForm, focus: _vm.filterReset }
    },
    [
      _c(
        "el-option",
        {
          staticClass: "options",
          attrs: { value: _vm.valueTitle, label: _vm.valueTitle }
        },
        [
          _c("el-tree", {
            ref: "selectTree",
            attrs: {
              "show-checkbox": _vm.multiple,
              id: "tree-option",
              data: _vm.companyTree,
              props: _vm.props,
              "node-key": _vm.props.value,
              "default-expanded-keys": _vm.defaultExpandedKey,
              "filter-node-method": _vm.filterCompanyNode
            },
            on: { check: _vm.getKeys, "node-click": _vm.handleNodeClick }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }